import { COLORS } from '@theme/colors';

const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '';
export const EXPERIMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_EXPERIMENT_ID || '';

export const COOKIE_KEY_POSTFIX =
  COOKIE_DOMAIN.replace('.platformart.com', '').replace('.', '-').replace('localhost', '-dev') || '-prod';

export const HOME_ROUTE = '/';

export enum MAIN_ANIMATION {
  DURATION = 0.6,
  REVERSE_DURATION = 0.6,
  MOVE_Y = 20,
}

export enum ROUTE {
  ACCOUNT = '/account',
  ACCOUNT_FAVORITES = '/account/favorites',
  ACCOUNT_DETAILS = '/account/profile',
  ACCOUNT_ORDERS = '/account/orders',
  ACCOUNT_ADDRESSES = '/account/addresses',
  ACCOUNT_ADDRESSES_ADD = '/account/addresses/add',
  GALLERY = '/gallery',
  GALLERIES = '/galleries',
  GALLERY_PROFILE = '/gallery/profile',
  GALLERY_LOGIN = '/gallery/login',
  ARTWORKS = '/artworks',
  ARTISTS = '/artists',
  SEARCH = '/search',
  LOGOUT = '/logout',
  FORGOT_PASSWORD = '/account/forgot-password',
  SIGN_UP = '/sign-up',
  PRIVACY_POLICY = '/privacy-policy',
  NEWSLETTER = '/newsletter',
  CONTACT = '/contact',
  CONTACT_US = '/contact-us',
  ABOUT = '/about',
  FAQ = '/faq',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  FEATURES = '/features',
  GIFT_CARD = '/gift-certificate',
  NOT_FOUND = '/404',
  ERROR = '/error',
  ADMIN_PREVIEW = '/admin/preview',
  HOME = '/',
  HOMEPAGE = '/homepage',
  EXCLUSIVE = '/exclusive',
  TRADE = '/trade',
  GROUPINGS = '/groupings',
  GALLERY_DOCUMENTS = '/gallery/documents',
  ARTIST_DESIGNED = '/artworks/groupings/artist-designed-product',
  OBJECTS = '/artworks/groupings/objects',
  GROUPINGS_ARTWORKS = '/artworks/groupings/new-artworks',
  CONSULTING = '/platform-art-consulting-services-art-advisory-curation-partnerships',
  PRIVATE_CLIENT = '/private-client',
  SHIPPING_RETURNS = '/shipping-returns',
}

export enum Z_INDEX {
  MOBILE_ARTWORK_DETAILS = 5,
  HEADER = 4,
  HEADER_BACKGROUND = 4,
  CUSTOM_SCROLL_TRACK = 3,
  CUSTOM_SCROLL_OVERLAY = 2,
  MONOGRAM = 5,
  COOKIE_BANNER = 6,
  SUBLEVEL = 2,
  HEADER_FILTERS = 2,
  ACCOUNT_HEADER_DROPDOWN = 3,
  ACCOUNT_HEADER_BACKGROUND = 2,
  SHOPPABLE_IMAGE_BUBBLE = 1,
}

export const OMNISEND_CONTACT_ID_COOKIE = 'omnisendContactID';

export enum ARROW_TYPE {
  PREV = 'prev',
  NEXT = 'next',
}

export enum LANDMARK_ROLES {
  BANNER = 'banner',
  COMPLEMENTARY = 'complementary',
  CONTENT_INFO = 'contentinfo',
  FORM = 'form',
  MAIN = 'main',
  NAVIGATION = 'navigation',
  REGION = 'region',
  SEARCH = 'search',
}

export const DROPDOWN_QUERY_PARAM = 'openDropdown';

export enum PRODUCT_TYPE {
  PRODUCT = 'PRODUCT',
  GIFT_CERTIFICATE = 'GIFT_CERTIFICATE',
}

export const DISABLE_ACCESSIBILITY_CLASS = 'disable-accessibility-styles';

export const DEFAULT_BACKGROUND_COLOR = COLORS.WHITE;
export const DEFAULT_TEXT_COLOR = COLORS.BLACK;

export const MAXIMUM_UNITS_PER_CART = 1;
export const HIDE_MAXIMUM_UNITS_REACHED_WARNING_DELAY = 5000;
